(function (w, d){
    function assetReccomend() { 
        
        /** Inialize Taboola */
        w._taboola = w._taboola || [];
        
        var widgets = d.querySelectorAll('.asset-recommend.taboola'),
            page = widgets[0].dataset.page;
            
        if (page == 'home') {
            _taboola.push({ home: 'auto' });
        } else if (page == 'category') {
            _taboola.push({ category: 'auto' });
        } else {
            _taboola.push({ article: 'auto' });
        }
    
        !function(e, f, u, i) {
            if (!d.getElementById(i)) {
                e.async = 1;
                e.src = u;
                e.id = i;
                f.parentNode.insertBefore(e, f);
            }
        }(d.createElement('script'),
            d.getElementsByTagName('script')[0],
            '//cdn.taboola.com/libtrc/townnews-network/loader.js',
            'tb_loader_script');
        if (w.performance && typeof w.performance.mark == 'function') {
            w.performance.mark('tbl_ic');
        }
    
        /** Push widgets */
        widgets.forEach(function(o) {
            _taboola.push({
                mode: o.dataset.mode,
                container: o.dataset.container,
                placement: o.dataset.placement,
                target_type: 'mix'
            });
        });
    
        /** Inform Taboola there are no more widgets */
        _taboola.push({ flush: true });
    };
    
    if (d.readyState == 'loading') {
        d.addEventListener('DOMContentLoaded', function() {
            assetReccomend();
        });
    } else {
        assetReccomend();
    }
    
})(window, document);